import { IterableStorageBase } from "./storage";

interface CachedData<T> {
    created: number,
    data: T
}

export default class MemoryStorage<T = any> extends IterableStorageBase<T> {
    private cache: {
        [key: string]: CachedData<T>
    }

    private readonly ttl?: number

    constructor(ttl?: number) {
        super()

        this.ttl = ttl
        this.cache = {}
    }

    clear(): Promise<void> {
        this.cache = {}

        return Promise.resolve()
    }

    getItem(key: string): Promise<T | null> {
        return new Promise(resolve => {
            setTimeout(() => {
                const hasKey = this.cache.hasOwnProperty(key)

                if (!hasKey) {
                    resolve(null)
                }

                const entry = this.cache[key]
                if (this.ttl && Date.now() - entry.created > this.ttl) {
                    resolve(null)
                }

                return resolve(entry.data)
            }, 0)
        })
    }

    removeItem(key: string): Promise<void> {
        return new Promise(resolve => {
            setTimeout(() => {
                if (!this.cache.hasOwnProperty(key)) {
                    resolve()
                }

                delete this.cache[key]

                resolve()
            }, 0)
        })
    }

    setItem(key: string, value: T): Promise<void> {
        return new Promise(resolve => {
            setTimeout(() => {
                this.cache[key] = {
                    created: Date.now(),
                    data: value
                }

                resolve()
            }, 0)
        })
    }

    async keys(): Promise<string[]> {
        return Object.keys(this.cache)
    }

    async isEmpty(): Promise<boolean> {
        return Object.keys(this.cache).length === 0
    }
}
