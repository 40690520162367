import qs from "qs"
import debugLib from "debug"

import ApiAdapter from "./ApiAdapter";
import { RequestAdapterInterface } from "./RequestAdapterInterface";

const debug = debugLib("prima:core:adapter:auth")

export default class AuthAdapter<RequestConfigType = any> extends ApiAdapter<RequestConfigType> {
    public constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter)
    }

    public async ensureAPIKey(getApiKey: () => Promise<string>) {
        const headers = await this.requestAdapter.getDefaultRequestHeaders()

        if (headers.common.Authentication) {
            debug("Authentication key is set - request should be authenticated!", headers.common.Auth)
            return
        }

        debug("Auth key not set. Updating it.")
        this.setAPIKey(await getApiKey())
    }

    public setAPIKey(apiKey: string) {
        const options = {
            common: {
                Authorization: `bearer ${apiKey}`
            }
        }

        this.requestAdapter.updateDefaultRequestHeaders(options)
    }

    public async login(username: string, password: string): Promise<string|false> {
        const {data: { success, token }} = await this._post(
            "core/users/login",
            qs.stringify({
                username,
                password,
            }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        )

        if (!success) {
            return false
        }

        if (!token) {
            throw "Server did not return api token"
        }

        return token
    }

    public async logout(): Promise<void> {
        await this._get("core/users/logout")
    }

    public async checkLogin(): Promise<boolean|null> {
        try {
            const { data } = await this._get("core/users/loggedin.json")

            return data
        } catch (error) {
            if (!error.response) {
                throw "Could not validate authentication. No response from server. Network down?"
            }

            if (error.response.status !== 401) {
                throw "Could not validate authentication. Server Error!"
            }

            return false
        }
    }
}
