import ControllerAdapter, { Model, PaginationParams, Paginator } from "./ControllerAdapter";
import { DateTime } from "luxon";
import debugLib from "debug"
import { RequestAdapterInterface } from "./RequestAdapterInterface";

const debug = debugLib("prima:core:adapter:user")

export interface UserModel extends Model {
    id?: number,
    group_id?: number,
    mandantor_id?: number,
    company_id?: number,
    role_id?: number,
    is_admin?: boolean,
    username: string,
    sex: "d" | "f" | "m",
    title?: string,
    name: string,
    surname: string,
    email: string,
    lang: string,
    reminds: number,
    enabled: boolean,
    privdisaccept: boolean,
    mustchpwd: boolean,
    lastlogin: DateTime,
    failedlogins: number,
    emailconfirmed: boolean,
}

export interface UserAddModel extends Partial<UserModel> {
    role_id: number,
    sex: UserModel['sex'],
    username: string,
    title?: string,
    name: string,
    email: string,
    lang: string,
    classifications?: {
        _ids: number[]
    },
}

export interface UserPaginator extends Paginator {
    users: UserModel[],
}

export default class UserAdapter<RequestConfigType> extends ControllerAdapter<RequestConfigType, UserModel, UserPaginator, UserAddModel> {
    public constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("core/users", "user", "users", adapter, {
            dateFields: ["created", "modified", "lastlogin"]
        })
    }

    public async whoami() {
        let user: UserModel;

        try {
            ({ data: user } = await this._get("whoami", {}));
        } catch (error) {
            throw error
        }

        return this.hydrateEntity(user)
    }

    public async search(search?: string, paginationParams?: PaginationParams): Promise<UserPaginator> {
        const { data: { users, paginator: { Users: paginator } } } = await this.paginate(
            "search",
            paginationParams,
            {
                params: {
                    search,
                }
            }
        )

        return {
            users,
            paginator
        }
    }
}
